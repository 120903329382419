import React from "react"

import Layout from "../../../../components/layout-static"
import Seo from "../../../../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Avoid 8 Costly Mistakes After Injury | Bartlett & Grippe",
    description:
      "Get the compensation you deserve by following eight simple rules to avoid costly mistakes insurance companies use to minimize your case. Our attorneys provide solutions to common tactics.",
    heroH1: "Eight Costly Mistakes To Avoid After Getting Injured",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Personal Injury mistakes that can hurt your results</h2>
            <h3>We help a fellow rider through the legal process.</h3>
            <p className="mb-8">
              Don’t give the insurance company an opportunity to minimize your
              case. If you want to get the maximum compensation you deserve
              please follow these 8 simple rules to avoid costly mistakes.
            </p>
            <p className="mb-8">
              Through representing my personal injury clients over the years,
              and being on the side of the insurance companies early in my
              career, I have received first-hand knowledge of the extended
              lengths insurance companies will go to avoid paying out on an
              insurance claim. From this, I’ve come up with the most common ways
              insurance companies try to expose an injury as less severe or
              non-existent in an effort to reduce your compensation. I’ve also
              provided a solution for avoiding the mistakes in each scenario
              below:
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <div className="mb-16">
            <h2>8) Failing to follow medical recommendations</h2>
            <p className="mb-8">
              Doctor’s recommendations exist to prevent further injury and aid
              in the healing process. Every plaintiff in a personal injury case
              has a duty to do everything they can to heal in the proper manner
              within the proper time frame. If those tips didn’t already remind
              you of receiving parental advice, this surely will:
            </p>
            <p className="mb-8">
              <strong>Solution:</strong> Follow the doctor’s orders and be extra
              careful to not participate in any activity that may lengthen your
              recovery or damage your claim. As an example, if you are told by a
              doctor to avoid sunlight to prevent scarring after surgery, but
              ignore their recommendation and go outside every day, insurance
              companies will argue that your scars are a result of your failure
              to follow your doctor’s orders and will be reluctant to include
              them as part of your claim.
            </p>
          </div>
          <div className="mb-16">
            <h2>7) Delays in medical treatment</h2>
            <p className="mb-8">
              Many clients say “I felt fine at the scene of the accident” only
              to find out the next day that they are in pain. This is a very
              common occurrence. Knowing the extent of your injuries may take
              time. When you are injured there are many things that occur
              internally that you don’t immediately notice. When tissues tear
              from an injury they swell, bleed, and may eventually lead to
              permanent internal scarring. This is why injured clients often
              feel much worse in the days and weeks following the injury. The
              results of some injuries last for a lifetime. How long the effects
              of your injury may last is determined by medical professionals
              based on your documented medical records related to the injury. In
              a typical scenario, someone who is injured seeks medical advice,
              then a short amount of time passes before any medical treatment
              and/or surgery occurs. This can happen for numerous reasons (such
              as seeking a second opinion, assessing insurance coverage,
              traveling to a specialist) at no fault of the injured victim.
              Insurance companies jump at this opportunity and claim that, since
              you temporarily delayed getting medical treatment, you weren’t
              injured to the extent you claimed or even injured at all. The
              insurance companies will use any reason to question the validity
              of an injury claim so here’s what to do:
            </p>
            <p className="mb-8">
              <strong>Solution:</strong> If you sustain an injury, seek
              treatment as soon as possible to put yourself in the best position
              to fully recover and eliminate any argument insurance companies
              may have about the truth or degree of your injury.
            </p>
          </div>
          <div className="mb-16">
            <h2>6) Missed medical appointments</h2>
            <p className="mb-8">
              Along the same lines as the validity of an injury being questioned
              due to gaps in treatment, I have seen multiple defendant’s
              attorneys argue in court that my client must not be injured
              because they had failed to attend a scheduled doctor’s
              appointment. It’s even worse if you fail to attend a scheduled
              doctor’s appointment and don’t call first to reschedule. To avoid
              this dilemma, heed this advice:
            </p>
            <p className="mb-8">
              <strong>Solution:</strong> If you can’t make an appointment, give
              as much notice as you can. Anything is better than an unexcused
              absence. Also, make sure the reasons for any appointment changes
              are documented and appointments that are rescheduled stay as close
              to the scheduled timeline as possible.
            </p>
            <p className="mb-8">
              NOTE: This does not mean that your case will be more favorable
              because you made more trips to the doctor’s office. Insurance
              companies and their expert witness physicians are well-trained at
              identifying fakers so that strategy will likely backfire.
            </p>
          </div>
          <div className="mb-16">
            <h2>5) Limited or no record of medical visits</h2>
            <p className="mb-8">
              If it’s not in the medical records, it didn’t happen. Insurance
              companies review all of your medical records to see if the
              complaints you are making in your case match up with what’s
              documented in your records. Some people are hesitant to talk to
              their doctors about issues that they don’t think are important or
              that they don’t think are related to their injury. As an example,
              if you hurt your neck in a car accident, your doctor doesn’t need
              to know about your fingers tingling, right? Wrong. Things that may
              seem unimportant or unrelated may be very significant. In the
              example above, the tingling in your fingers could be an indication
              of an impinged or injured nerve in your neck.
            </p>
            <p className="mb-8">
              <strong>Solution:</strong> When you go in to see your doctor it is
              important that you tell them about all of the issues you are
              having, even those things you don’t think are important. By doing
              so, you give your doctor the best information to allow him to
              diagnose your condition, treat you, and help you obtain a full and
              quick recovery. Additionally, by telling your doctor about all of
              your pain complaints and other issues you have been having since
              the accident, it provides us with a complete medical record that
              we can rely on to establish the impact of your injuries and the
              connection between your injuries and your accident when dealing
              with the insurance company.
            </p>
          </div>
          <div className="mb-16">
            <h2>4) Gaps in medical treatment</h2>
            <p className="mb-8">
              Often times life events will cause a client to have to take a
              break from their scheduled medical treatment, such as ongoing
              physical therapy appointments. If you begin treatment and then
              take a break before your treatment is completed; insurance
              companies will try to argue that any treatment after that break is
              not related to your accident.
            </p>
            <p className="mb-8">
              <strong>Solution:</strong> If you have to take a break from your
              treatment, make sure you tell your doctors why you need time off
              and what, if any, changes occurred over the course of your break,
              when you resume treatment. This will not only help your doctors
              treat your injuries, but will circumvent the insurance company’s
              argument.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <div className="mb-16">
            <h2>
              3) I didn’t tell the doctor about pain related to a pre-existing
              injury. I thought that didn’t count.
            </h2>
            <p className="mb-8">
              Most people are injured at some point in their life, and these
              previous injuries may make them more susceptible to medical
              problems arising from additional injuries. Some people think that
              if they don’t tell their doctors about these prior injuries, they
              have a better chance of recovering more money in their lawsuit.
              Not only will failing to tell your doctors about prior injuries
              affect your credibility in your lawsuit, it may make it more
              difficult for your doctors to properly diagnose your injuries and
              treat the root problem, potentially delaying your recovery.
            </p>
            <p className="mb-8">
              <strong>Solution:</strong> Tell the doctor about all symptoms
              related to your injury or accident, as well as any prior similar
              injuries you have had. Make sure you let your doctors know about
              any pain or other symptoms you were experiencing prior to the
              accident, and how those symptoms changed as a result of the
              accident. In Connecticut, even if you have a pre-existing injury,
              if that injury was not causing you issues at the time of the
              accident, the insurance company cannot attempt to diminish the
              value of your case by arguing that you were more susceptible to an
              injury as a result of your pre-existing condition. Even in cases
              where a prior injury was actively causing pain or other symptoms,
              the at fault party remains responsible for any changes
              attributable to the accident. By being honest with your doctors
              you give them the information they need to help you and avoid
              losing credibility with the insurance company.
            </p>
          </div>
          <div className="mb-16">
            <h2>2) Don’t exaggerate your injuries</h2>
            <p className="mb-8">
              Some people think that if they claim that they can’t do anything
              after an accident, their case is automatically worth more.
              Insurance companies rarely take you at your word, and will likely
              investigate your accident and your claims to ensure that the
              claims you are making match up with the injuries that are
              documented in your medical records. In certain cases, they may
              even send a private investigator out to see if you are still
              engaging in the activities you claim you aren’t able to do as a
              result of the accident. If the records don’t match up or worse, if
              an investigator takes surveillance video of you doing things you
              claim you can’t, your case will be worth significantly less than
              if you had been honest from the outset about the limitations your
              injuries have caused.
            </p>
            <p className="mb-8">
              <strong>Solution:</strong> Honesty is the best policy. If you are
              injured as a result of an accident and are limited in what you can
              do, as a result, make sure your doctors and your attorney know
              about those limitations. Don’t, however, make up additional issues
              just to make your case sound better. By being honest with your
              doctors and attorney, you can avoid the insurance company’s claim
              that you are lying about your limitations and the injuries you
              sustained.
            </p>
          </div>

          <div className="mb-16">
            <h2>1) Be honest</h2>
            <p className="mb-8">
              Along the same lines as not exaggerating your injuries, there are
              some people who think that, because doctors can’t see or test for
              “pain” if they tell their doctors that everything they do hurts,
              their doctors will diagnose them with more significant injuries
              and their case will be worth more. Although doctors can’t see or
              test for “pain”, doctors do have tests that they can perform to
              determine if your subjective pain complaints are consistent with
              things they can test for. If you have a back injury and your
              doctor asks you to raise your leg or bend over and touch your
              toes, they are checking to see if your responses match up to what
              would be expected from people with similar injuries. Some of these
              movements are designed to decrease pain rather than increase it.
              By lying to your doctor and saying that a certain movement hurts
              when it doesn’t, you could be giving your doctor a reason to doubt
              the truth of your complaints and your injuries. Insurance
              companies will highlight a doctor’s report that states that your
              subjective complaints are inconsistent with their objective
              findings and will use that to minimize your case.
            </p>
            <p className="mb-8">
              <strong>Solution:</strong> Again, this pitfall can be avoided by
              simply being honest with your doctors. Make sure you tell your
              doctors about all of the issues you are having, what increases
              them, and what provides relief. By being honest with your doctors
              you give them the information they need and put yourself on the
              road to recovery and avoid the biggest pothole that can derail
              your lawsuit.
            </p>
            <p className="mb-8">
              <strong>
                I tell every one of my clients that your first priority after an
                accident is to focus on getting better. By focusing on your
                recovery, being honest and listening to your doctors, you put
                yourself in the best position to obtain a full and quick
                recovery and simultaneously eliminate some key arguments the
                insurance companies try to use to minimize or avoid paying
                claims altogether. Once you become a client of Bartlett & Grippe
                we will discuss these rules, and many more.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
